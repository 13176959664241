import { API_CONTRACTS } from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class ContractService {
    async getContracts(query) {
        const result = await Fetch.make().get(API_CONTRACTS, {authHeader: true, query})
        return result
    }
    
}

export default new ContractService();