import {
    API_POSTS,
    API_POST_CREATE,
    API_CREATE_CONTENT,
    API_RENDER_IMAGE,
    API_RENDER_TAGS,
    API_UPLOAD_IMAGES,
    API_REMOVE_IMAGES,
    API_DETAIL_POST,
    API_DETAIL_POST_CUSTOMER
} from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class Post {
    async getPosts() {
        const result = await Fetch.make().get(API_POSTS, { authHeader: true });
        return result;
    }

    async createPost(payload) {
        const url = API_POST_CREATE.replace(":id", payload.id);
        const result = await Fetch.make().put(url, payload, {
            authHeader: true,
        });
        return result;
    }
    async createContent(payload) {
        const result = await Fetch.make().post(API_CREATE_CONTENT, payload, {
            authHeader: true,
        });
        return result;
    }
    async renderImage(payload) {
        const result = await Fetch.make().post(API_RENDER_IMAGE, payload, {
            authHeader: true,
        });
        return result;
    }
    async renderTags() {
        const result = await Fetch.make().get(API_RENDER_TAGS, {
            authHeader: true,
        });
        return result;
    }

    async uploadImages(payload) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('ADSBASE-TOKEN'));
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: payload,
            redirect: "follow",
        };

        const result = await fetch(API_UPLOAD_IMAGES, requestOptions);
        const data = await result.json();
        return data
    }
    async removeImage(payload) {
        const result = await Fetch.make().put(API_REMOVE_IMAGES, payload, {
            authHeader: true,
        });
        return result;
    }

    async getDetail(id) {
        const url = API_DETAIL_POST.replace(":id", id);
        const result = await Fetch.make().get(url, { authHeader: true });
        return result;
    }

    async getDetailCustomerReview(payload) {
        const url = API_DETAIL_POST_CUSTOMER.replace(":id", payload.id);
        const result = await Fetch.make().get(url, { authHeader: true, query: payload.query });
        return result;
    }
}

export default new Post();
